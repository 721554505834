import { useStaticQuery, graphql } from 'gatsby';
import React, { useEffect } from 'react';

import { ContentfulDiscussionNode } from 'models/contentfulModels';

const Redirect = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      discussions: allContentfulDiscussions {
        edges {
          node {
            swarmcheckId
            slug
          }
        }
      }
    }
  `);

  useEffect(() => {
    const apiId = window.location.href.split('?id=').pop();
    const url = window.location.href.split('/discussion').shift();

    const discussion = data.discussions.edges.find((d: ContentfulDiscussionNode) => 
      d.node.swarmcheckId === parseInt(apiId ? apiId : '')
    );
    window.location.href = `${url}/dyskusje/${discussion.node.slug}`;
  }, [data.discussions.edges]);

  return (<div></div>);
};

export default Redirect;